import { tagAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';

import getStylesByColorScheme from 'theme/utils/getStylesByColorScheme';

import getDefaultTransitionProps from '../../utils/getDefaultTransitionProps';
import Badge from '../Badge';
const transitionProps = getDefaultTransitionProps();

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const variants = {
  subtle: definePartsStyle((props) => ({
    container: Badge.variants?.subtle(props),
  })),
  primary: definePartsStyle((props) => {
    const { borderColor, bgTransparent } = getStylesByColorScheme(props.colorScheme);
    return {
      container: {
        border: '1px solid',
        borderColor,
        bg: bgTransparent,
        display: 'flex',
        alignItems: 'center',
        h: '33px',
        py: 2,
        px: 3,
        color: 'white',
        borderRadius: '2xl',
        fontWeight: 500,
      },
      label: {
        lineHeight: 1.5,
      },
    };
  }),
  secondary: definePartsStyle((props) => {
    const { tagStyles } = getStylesByColorScheme(props.colorScheme);
    return {
      container: {
        display: 'flex',
        alignItems: 'center',
        h: '22px',
        border: 0,
        borderRadius: '30px',
        fontWeight: 600,
        fontSize: 'xs',
        lineHeight: '14px',
        ...tagStyles,
      },
    };
  }),

};

const sizes = {
  sm: definePartsStyle({
    container: {
      minH: 5.5,
      minW: 5.5,
      fontSize: 'sm',
      px: 2,
      py: 1,
      lineHeight: 5,
    },
  }),
};

const baseStyleContainer = defineStyle({
  display: 'inline-block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  borderRadius: '2xl',
  border: 'none',
  color: 'white',
  ...transitionProps,
});

const baseStyle = definePartsStyle({
  container: baseStyleContainer,
});

const Tag = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    size: 'sm',
    variant: 'subtle',
    colorScheme: 'gray',
  },
});

export default Tag;
