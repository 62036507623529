import type { SystemStyleInterpolation } from '@chakra-ui/styled-system';
import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

import getDefaultTransitionProps from '../utils/getDefaultTransitionProps';

const baseStyle = defineStyle(getDefaultTransitionProps());

const variantPrimary = defineStyle((props) => {
  return {
    color: 'link',
    _hover: {
      color: 'link_hovered',
      textDecorationStyle: props.textDecorationStyle || 'solid',
    },
  };
});

const variantSecondary = defineStyle((props) => {
  return {
    color: mode('gray.600', 'gray.500')(props),
    _hover: {
      color: mode('gray.600', 'gray.400')(props),
    },
  };
});

const variantButton = defineStyle((props) => {
  return {
    margin: 'auto',
    p: '10px 16px',
    borderRadius: 'sm',
    bg: mode('yellow.100', 'yellow.100')(props),
    textAlign: 'center',
    textTransform: 'capitalize',
    fontWeight: 500,
    lineHeight: '20px',
    color: mode('black', 'black')(props),
    textDecoration: 'none !important',
    _hover: {
      bg: mode('orange.100', 'orange.100')(props),
    },
  };
});

const variants: Record<string, SystemStyleInterpolation> = {
  primary: variantPrimary,
  secondary: variantSecondary,
  button: variantButton,
};

const defaultProps = {
  variant: 'primary',
};

const Link = defineStyleConfig({
  variants,
  defaultProps,
  baseStyle,
});

export default Link;
