import { Flex, chakra } from '@chakra-ui/react';
import React from 'react';

export const MAX_WIDTH_LAYOUT = '1425px'; // 1385px + 40px = paddingX | padding left / right = 20px

interface Props {
  className?: string;
  children: React.ReactNode;
}

const MainColumn = ({ children, className }: Props) => {
  return (
    <Flex
      className={ className }
      flexDir="column"
      flexGrow={ 1 }
      w={{ base: '100%' }}
      paddingTop={{ base: 3, lg: 12 }}
      paddingBottom={ 10 }
      margin="auto"
      maxW={ MAX_WIDTH_LAYOUT }
      px={ 5 }

    >
      { children }
    </Flex>
  );
};

export default React.memo(chakra(MainColumn));
