import {
  Text,
  HStack,
  Box,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  useBoolean,
} from '@chakra-ui/react';
import React from 'react';

import type { NavGroupItem } from 'types/client/navigation-items';

import IconSvg from 'ui/shared/IconSvg';

import NavLink from './NavLink';
import useNavLinkStyleProps from './useNavLinkStyleProps';

type Props = {
  item: NavGroupItem;
  isCollapsed?: boolean;
};

const NavLinkGroupDesktop = ({ item, isCollapsed }: Props) => {
  const isExpanded = isCollapsed === false;
  const [ isHovered, setIsHovered ] = useBoolean();

  const styleProps = useNavLinkStyleProps({
    isHovered,
    isCollapsed,
    isExpanded,
    isActive: item.isActive,
  });

  return (
    <Box as="li" listStyleType="none" w="max-content">
      <Popover trigger="hover" placement="bottom-start" isLazy>
        <PopoverTrigger>
          <Link
            { ...styleProps.itemProps }
            w={{ base: 'max-content' }}
            aria-label={ `${ item.text } link group` }
            position="relative"
          >
            <HStack overflow="hidden" gap="4px">
              <Text { ...styleProps.textProps }>{ item.text }</Text>
              <IconSvg
                name="arrows/east-mini"
                boxSize={ 2 }
                ml={ 1 }
                transform={ isHovered ? 'rotate(180deg)' : 'none' }
                transitionProperty="transform"
                transitionDuration="normal"
                transitionTimingFunction="ease"
              />
            </HStack>
          </Link>
        </PopoverTrigger>
        <PopoverContent
          width="229px"
          onMouseEnter={ setIsHovered.on }
          onMouseLeave={ setIsHovered.off }>
          <PopoverBody py={ 4 } px={ 6 }>
            <Text
              variant="secondary"
              fontSize="sm"
              mb={ 2 }
              display={{
                lg: isExpanded ? 'none' : 'block',
                xl: isCollapsed ? 'block' : 'none',
              }}
            >
              { item.text }
            </Text>
            <VStack spacing={ 1 } alignItems="start">
              { item.subItems.map((subItem, index) =>
                Array.isArray(subItem) ? (
                  <Box
                    key={ index }
                    w="100%"
                    as="ul"
                    _notLast={{
                      mb: 2,
                      pb: 2,
                      borderBottomWidth: '1px',
                      borderColor: 'divider',
                    }}
                  >
                    { subItem.map((subSubItem) => (
                      <NavLink
                        key={ subSubItem.text }
                        item={ subSubItem }
                        isCollapsed={ false }
                      />
                    )) }
                  </Box>
                ) : (
                  <NavLink
                    key={ subItem.text }
                    item={ subItem }
                    px={ 0 }
                    containerProps={{
                      w: '100%',
                      borderRadius: 'base',
                      _hover: {
                        bg: 'blue.550',
                        boxShadow: 'lg',
                      },
                    }}
                    isCollapsed={ false }
                  />
                ),
              ) }
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default NavLinkGroupDesktop;
