const colors = {
  // MULTI BANK COLORS -->
  gold: {
    '100': '#FCC678',
    '200': '#BB9049',
  },
  lightBlue: {
    '100': '#AFBCCF',
  },
  blue: {
    '100': '#5595FC',
    '200': '#4477CA',
    '300': '#335996',
    '400': '#2F364A',
    '550': '#2B3245',
    '600': '#20283B',
    '650': '#151B29',
    '700': '#161D2F',
    '800': '#111828',
    '850': '#101828',
    '900': '#0B1120',
  },
  blueAlpha: {
    '100': '#5595FC80', // opacity: 50%
  },
  cyan: {
    '100': '#07D6F2',
  },
  cyanAlpha: {
    '100': '#0098AD80', // opacity: 50%
  },
  yellow: {
    '100': '#FFB811',
  },
  yellowAlpha: {
    '100': '#FFB81133', // opacity: 20%
  },
  orange: {
    '100': '#F59B47',
    '200': '#8F5A29',
    '800': '#3A3133',
  },
  orangeAlpha: {
    '100': '#F59B4780', // opacity: 50%
  },
  green: {
    '100': '#21CB80',
    '200': '#106540',
    '300': '#143C3A',
  },
  greenAlpha: {
    '100': '#21CB8080', // opacity: 50%
  },
  gray: {
    '100': '#646D83',
    '200': '#59667D',
    '400': '#444C62',
  },
  black: '#0D0D0D',
  white: '#ffffff',
  whiteAlpha: {
    '50': '#ffffff14', // opacity: 8%
    '100': '#ffffff1a', // opacity: 10%
    '300': '#ffffff4D', // opacity: 30%
  },
  red: {
    '100': '#FED7D7',
    '400': '#F5475D',
    '500': '#E53E3E',
  },
  redAlpha: {
    '100': '#F5475D80', // opacity: 30%
  },

  // <--

  blackAlpha: {
    '50': 'RGBA(16, 17, 18, 0.04)',
    '100': 'RGBA(16, 17, 18, 0.06)',
    '200': 'RGBA(16, 17, 18, 0.08)',
    '300': 'RGBA(16, 17, 18, 0.16)',
    '400': 'RGBA(16, 17, 18, 0.24)',
    '500': 'RGBA(16, 17, 18, 0.36)',
    '600': 'RGBA(16, 17, 18, 0.48)',
    '700': 'RGBA(16, 17, 18, 0.64)',
    '800': 'RGBA(16, 17, 18, 0.80)',
    '900': 'RGBA(16, 17, 18, 0.92)',
  },
};

export const gradients = {
  primary: 'radial-gradient(50% 50% at 15.31% 50.26%, #1A2234 0%, #101828 100%);',
  borderGradient: `linear-gradient(99.93deg, 
    rgba(136, 149, 172, 0.4) 0%, 
    rgba(32, 40, 59, 0.4) 10.42%, 
    rgba(74, 92, 135, 0.4) 32.81%, 
    rgba(32, 40, 59, 0.4) 100%
  )`,
  gold: `linear-gradient(90deg, ${ colors.gold[100] }, ${ colors.gold[200] } 100%)`,
  blue: `linear-gradient(180deg, ${ colors.blue[100] }, ${ colors.blue[300] } 100%)`,
  orange: `linear-gradient(180deg, ${ colors.orange[100] }, ${ colors.orange[200] } 100%)`,
  green: `linear-gradient(180deg, ${ colors.green[100] }, ${ colors.green[200] } 100%)`,
};

export const dropShadows = {
  primary: '0px 4px 4px #00000059',
};

export default colors;
