import { Box, Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';

import { clearRecentSearchKeywords, getRecentSearchKeywords, removeRecentSearchKeyword } from 'lib/recentSearchKeywords';
import ClearButton from 'ui/shared/ClearButton';
import HashStringShortenDynamic from 'ui/shared/HashStringShortenDynamic';

type Props = {
  onClick: (kw: string) => void;
  onClear: () => void;
}

const SearchBarSuggest = ({ onClick, onClear }: Props) => {

  const [ keywords, setKeywords ] = React.useState<Array<string>>(getRecentSearchKeywords());

  const handleClick = React.useCallback((kw: string) => () => {
    onClick(kw);
  }, [ onClick ]);

  const clearKeywords = React.useCallback(() => {
    clearRecentSearchKeywords();
    onClear();
  }, [ onClear ]);

  const removeKeyword = React.useCallback((kw: string) => (e: React.SyntheticEvent) => {
    e.stopPropagation();
    const result = keywords.filter(item => item !== kw);
    setKeywords(result);
    if (result.length === 0) {
      onClear();
    }
    removeRecentSearchKeyword(kw);
  }, [ keywords, onClear ]);

  if (keywords.length === 0) {
    return null;
  }

  return (
    <Box py={ 5 }>
      <Flex mb={ 2 } justifyContent="space-between" fontSize="sm">
        <Text fontSize="xs" fontWeight={ 600 } variant="secondary">Recent</Text>
        <Link fontSize="xs" fontWeight={ 600 } onClick={ clearKeywords }>Clear all</Link>
      </Flex>
      { keywords.map(kw => (
        <Flex
          key={ kw }
          onClick={ handleClick(kw) }
          py={ 3 }
          px={ 2 }
          rowGap={ 2 }
          borderRadius="base"
          border="1px solid transparent"
          _hover={{
            bgColor: 'blue.600',
            borderColor: 'link_hovered',
          }}
          fontSize="xs"
          color="white"
          alignItems="center"
          justifyContent="space-between"
          columnGap={ 2 }
          fontWeight={ 700 }
          minW={ 0 }
          flexGrow={ 1 }
          cursor="pointer"
        >
          { kw.startsWith('0x') ? (
            <Box overflow="hidden" whiteSpace="nowrap">
              <HashStringShortenDynamic hash={ kw } isTooltipDisabled/>
            </Box>
          ) :
            <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">{ kw }</Text>
          }
          <ClearButton onClick={ removeKeyword(kw) } flexShrink={ 0 }/>
        </Flex>
      )) }
    </Box>
  );
};

export default SearchBarSuggest;
