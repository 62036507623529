import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/styled-system';

import { dropShadows } from 'theme/foundations/colors';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const $bg = cssVar('menu-bg');
const $shadow = cssVar('menu-shadow');

const baseStyleList = defineStyle({
  [$bg.variable]: 'colors.blue.600',
  [$shadow.variable]: dropShadows.primary,
  _dark: {
    [$bg.variable]: 'colors.blue.600',
    [$shadow.variable]: dropShadows.primary,
  },
  borderWidth: '0',
  bg: $bg.reference,
  boxShadow: $shadow.reference,
  p: 0,
  overflow: 'hidden',
  borderRadius: 4,
});

const baseStyleItem = defineStyle({
  _hover: {
    [$bg.variable]: 'colors.blue.400',
  },
  bg: $bg.reference,
  p: 4,
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '20px',
});

const baseStyle = definePartsStyle({
  list: baseStyleList,
  item: baseStyleItem,
});

const Menu = defineMultiStyleConfig({
  baseStyle,
});

export default Menu;
