import type { ColorScheme, TagStyles } from 'types/colorSchemes';

const getStylesByColorScheme = (colorScheme: string): ColorScheme => {

  switch (colorScheme) {
    case 'orange':
      return {
        bg: 'orange.100',
        bgTransparent: 'orangeAlpha.100',
        borderColor: 'orange.100',
        color: 'orange.100',
        gradient: 'gradients.orange',
        tagStyles: {
          bg: 'orange.800',
          color: 'orange.100',
        },
      };
    case 'green':
      return {
        bg: 'green.100',
        bgTransparent: 'greenAlpha.100',
        borderColor: 'green.100',
        color: 'green.100',
        gradient: 'gradients.green',
        tagStyles: {
          bg: 'green.300',
          color: 'green.100',
        },
      };
    case 'cyan':
      return {
        bg: 'cyan.100',
        bgTransparent: 'cyanAlpha.100',
        borderColor: 'cyan.100',
        color: 'white',
        tagStyles: {
          bg: 'cyanAlpha.100',
          color: 'cyan.100',
        },
      };
    case 'red':
      return {
        tagStyles: {
          bg: 'redAlpha.100',
          color: 'red.400',
        },
      };
    case 'gray':
      return {
        tagStyles: defaultTagStyles,
      };
    default:
      return {
        bg: 'blue.100',
        bgTransparent: 'blueAlpha.100',
        borderColor: 'blue.100',
        color: 'blue.100',
        gradient: 'gradients.blue',
        tagStyles: defaultTagStyles,
      };
  }
};

const defaultTagStyles: TagStyles = {
  bg: 'gray.400',
  color: 'lightBlue.100',
};

export default getStylesByColorScheme;
