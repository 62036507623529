import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import { runIfFn } from '@chakra-ui/utils';

const variantSolid = defineStyle((props) => {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    const bg = mode(`gray.100`, `whiteAlpha.200`)(props);

    return {
      bg,
      _hover: {
        bg: mode(`gray.200`, `whiteAlpha.300`)(props),
        _disabled: {
          bg,
        },
      },
      _active: { bg: mode(`gray.300`, `whiteAlpha.400`)(props) },
    };
  }

  const bg = 'blue.700';
  const color = 'white';
  const hoverColor = 'black';
  const hoverBg = 'yellow.100';
  const activeBg = 'orange.100';

  return {
    bg,
    color,
    _hover: {
      bg: hoverBg,
      color: hoverColor,
      _disabled: {
        bg,
      },
    },
    _disabled: {
      opacity: 0.2,
    },
    _active: {
      bg: activeBg,
      color: hoverColor,
    },
    fontWeight: 600,
  };
});

const variantOutline = defineStyle((props) => {
  const disabledColor = mode('whiteAlpha.300', 'whiteAlpha.300')(props); // opacity: 30%
  const color = props.isDisabled ? disabledColor : mode('white', 'white')(props);
  const activeColor = props.isDisabled ? disabledColor : mode('link_hovered', 'link_hovered')(props);
  const borderColor = mode('blue.600', 'blue.600')(props);
  const activeBg = mode('blue.700', 'blue.700')(props);
  const bg = props.bg;

  return {
    color,
    fontWeight: props.fontWeight || 500,
    borderRadius: 'sm',
    borderWidth: props.borderWidth || '1px',
    borderStyle: 'solid',
    borderColor,
    bg: bg ?? 'transparent',
    _hover: {
      color: 'link_hovered',
      borderColor: 'link_hovered',
      bg: bg ?? 'transparent',
      _active: {
        bg: props.isActive ? activeBg : 'transparent',
        borderColor: props.isActive ? activeBg : 'link_hovered',
        color: props.isActive ? activeColor : 'link_hovered',
        p: {
          color: 'link_hovered',
        },
      },
      _disabled: {
        color: color,
        borderColor,
      },
      '&:enabled svg,p': {
        color: 'link_hovered',
      },
    },
    _active: {
      bg: activeBg,
      borderColor: 'transparent',
      color: activeColor,
      _disabled: {
        color,
        borderColor,
      },
      p: {
        color: activeColor,
      },
    },
  };
});

const variantSimple = defineStyle((props) => {
  const outline = runIfFn(variantOutline, props);

  return {
    color: outline.color,
    _hover: {
      color: outline._hover.color,
    },
  };
});

const variantGhost = defineStyle((props) => {
  const { color } = props;

  const activeStyles = {
    color: 'link_hovered',
    bg: 'activeBackground',
  };

  return {
    color: 'white',
    border: '1px solid transparent',
    _active: {
      ...activeStyles,
      color: color ? color : 'link_hovered',
    },
    _hover: {
      bg: 'blue.550',
      color: `link_hovered`,
      _active: activeStyles,
    },
  };
});

const variantSubtle = defineStyle((props) => {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    return {
      bg: mode('blackAlpha.200', 'whiteAlpha.200')(props),
      color: mode('blackAlpha.800', 'whiteAlpha.800')(props),
      _hover: {
        color: 'link_hovered',
        _disabled: {
          color: mode('blackAlpha.800', 'whiteAlpha.800')(props),
          bg: mode('blackAlpha.200', 'whiteAlpha.200')(props),
        },
      },
    };
  }

  return {
    bg: `${ c }.100`,
    color: `${ c }.600`,
    _hover: {
      color: 'link_hovered',
    },
  };
});

const variantAction = defineStyle(() => {
  const styles = {
    bg: 'blue.600',
    color: 'white',
  };
  return {
    ...styles,
    _hover: {
      bg: 'blue.400',
      color: 'link_hovered',
      _disabled: styles,
    },
  };
});

const variantMenuButton = defineStyle(() => {
  return {
    textAlign: 'left',
    bg: 'blue.600',
    borderColor: 'transparent',
    borderWidth: 1,
    color: 'lightBlue.100',
    _hover: {
      borderWidth: 1,
      borderColor: 'yellow.100',
    },
    _disabled: {
      opacity: 0.2,
    },
    _active: {
      borderWidth: 1,
      borderColor: 'yellow.100',
    },
    fontWeight: 600,
    padding: '16px !important',
    height: 58,
  };
});

const variants = {
  solid: variantSolid,
  outline: variantOutline,
  simple: variantSimple,
  ghost: variantGhost,
  subtle: variantSubtle,
  action: variantAction,
  menuButton: variantMenuButton,
};

const baseStyle = defineStyle({
  fontWeight: 600,
  borderRadius: 'base',
  overflow: 'hidden',
  padding: '16px 10px',
  _focusVisible: {
    boxShadow: { base: 'none', lg: 'outline' },
  },
});

const sizes = {
  lg: defineStyle({
    h: 12,
    minW: 'unset',
    fontSize: 'lg',
    px: 6,
  }),
  md: defineStyle({
    h: 10,
    minW: 'unset',
    fontSize: 'md',
    px: 6,
  }),
  sm: defineStyle({
    h: '37px',
    minW: 'unset',
    fontSize: 'sm',
    p: '10px 9.5px',
  }),
  xs: defineStyle({
    h: 6,
    minW: 'unset',
    fontSize: 'xs',
    px: 2,
  }),
};

const Button = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'blue',
  },
});

export default Button;
