import { Box, Divider, Flex, Text, useColorModeValue, useToken } from '@chakra-ui/react';
import React from 'react';

import IconSvg from 'ui/shared/IconSvg';
import TextSeparator from 'ui/shared/TextSeparator';

import FooterLinkItem from './FooterLinkItem';

const Footer = () => {
  const borderColor = useToken('colors', 'divider');
  const color = useColorModeValue('lightBlue.100', 'lightBlue.100');

  const MULTI_BANK_LINKS = [
    {
      icon: 'social/linkedin' as const,
      url: 'https://www.linkedin.com/company/multibank-io',
    },
    {
      icon: 'social/facebook' as const,
      url: 'https://www.facebook.com/MultiBank.io/',
    },
    {
      icon: 'social/instagram' as const,
      url: 'https://www.instagram.com/multibank_io/',
    },
    {
      icon: 'social/twitter' as const,
      url: 'https://x.com/multibank_io',
    },
    {
      icon: 'social/telegram' as const,
      url: 'https://t.me/MultiBank_io',
    }, {
      icon: 'social/telegram-announcement' as const,
      url: 'https://t.me/MultiBankio_Announcements',
    },
  ];

  const MULTI_BANK_DOCS_LINKS = [
    {
      title: 'Cookie Policy',
      url: 'https://multibank.io/about/gcc/cookie-policy',
    },
    {
      title: 'Terms & Conditions',
      url: 'https://multibank.io/about/gcc/terms-and-conditions',
    },
    {
      title: 'Disclaimer',
      url: 'https://multibank.io/about/gcc/disclaimer',
    },
    {
      title: 'Code of Conduct',
      url: 'https://multibank.io/about/gcc/code-of-conduct',
    },
    {
      title: 'Public Complaint Policy',
      url: 'https://multibank.io/about/gcc/complaint-policy',
    },
    {
      title: 'Public Disclosure',
      url: 'https://multibank.io/about/gcc/public-disclosure',
    },
    {
      title: 'Privacy Policy',
      url: 'https://multibank.io/about/gcc/privacy-policy',
    },
    {
      title: 'Broker Dealer Services',
      url: 'https://multibank.io/legal/broker-dealer-services',
    },
    {
      title: 'Exchange Services',
      url: 'https://multibank.io/legal/exchange-services',
    },
    {
      title: 'Client Agreement',
      url: 'https://multibank.io/about/gcc/client-agreement',
    },
    {
      title: 'VA Standards',
      url: 'https://multibank.io/about/gcc/virtual-asset-standards',
    },
  ];

  const lastIndex = MULTI_BANK_DOCS_LINKS.length - 1;

  return (
    <Flex as="footer" w="100%" padding={{ base: 4, lg: '70px' }} flexDirection="column" bg="blue.800">
      <Flex w="100%" h={{ base: 'auto', lg: '33px' }} flexDirection={{ base: 'column', lg: 'row' }} justifyContent="space-between" alignItems="center">
        <IconSvg name="multi-bank/logo-light"/>
        <Flex h="100%" alignItems="center" flexDirection={{ base: 'column', lg: 'row' }}>
          <Flex as="ul" gap={ 5 }>
            { MULTI_BANK_LINKS.map((item) => (
              <FooterLinkItem { ...item } key={ item.icon }/>
            )) }
          </Flex>
          <Flex alignItems="center" mt={{ base: 4, lg: 0 }}>
            <Text paddingX={{ base: 2, lg: 5 }} borderRight="1px solid white" color="white">
            Official Partner
            </Text>
            <IconSvg maxW="206px" w="100%" h="32px" paddingX={{ base: 2, lg: 5 }} name="multi-bank/partners-icon"/>
          </Flex>
        </Flex>
      </Flex>
      <Divider my={ 6 } borderBottomColor="blue.550"/>
      <Flex mb="30px" flexDirection={{ base: 'column', lg: 'row' }} fontSize="xs">
        <Box
          flex="50%"
          paddingRight={{ base: 0, lg: 8 }}
          borderRight={{ base: 'none', lg: `1px solid ${ borderColor }` }}
        >
          <Text marginBottom="30px" color={ color }>2024 Copyright. All Rights Reserved. MultiBank io.</Text>
          <Text marginBottom={{ base: '30px', lg: 0 }} color={ color }>
            MEX Digital Pty Ltd (ABN 54 645 164 209), is a Corporate Authorised
            Representative (AFS Representative Number 001285048) of MEX
            Australia Pty Ltd (AFSL 416279) at SE 6103, FL 61, 19 Martin PL,
            SYDNEY, NSW 2000 is registered as a Digital Currency Exchange with
            AUSTRAC (Registration No. 100724469) through which we offer our Spot
            products. Bridge Tech Limited, trading as Mex-D is a Seychelles
            registered company with the incorporation number of 8424231-1 and
            regulated by the Financial Services Authority Seychelles under the
            licence number SD086 facilitates our Leveraged products.
          </Text>
        </Box>
        <Box flex="50%" paddingLeft={{ base: 0, lg: 8 }}>
          <Text color={ color }>
            Risk Warning: Purchasing, holding, and trading digital financial
            assets may not be suitable for everyone as there are risks involved,
            therefore, seek professional financial advice from a suitable and
            licensed financial advisor before investing. We are not a financial
            advisor, and all services are provided on an execution only basis.
            We do not issue advice, recommendations, or opinion in relation to
            acquiring, holding, or disposing of any digital financial asset.
            Under no circumstances shall MultiBank be liable for any loss or
            damage to any entity or person for any damage or loss resulting from
            or caused by and relating to any transactions. The information on
            this site is not directed to the residents of the United States,
            Japan or any particular country and is not intended for distribution
            to, or use by, any person in any country or jurisdiction where such
            distribution or use would be contrary to local laws or regulations.
          </Text>
        </Box>
      </Flex>
      <Flex color={ color } fontSize="xs" flexWrap="wrap">
        { MULTI_BANK_DOCS_LINKS.map((item, index) => (
          <>
            <FooterLinkItem { ...item } key={ item.title }/>
            { index !== lastIndex && <TextSeparator color={ color } mx={ 1 }/> }
          </>
        )) }
      </Flex>
    </Flex>
  );
};

export default React.memo(Footer);
