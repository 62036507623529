import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
  cssVar,
} from '@chakra-ui/styled-system';
import { runIfFn } from '@chakra-ui/utils';

import getDefaultTransitionProps from 'theme/utils/getDefaultTransitionProps';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const $size = cssVar('checkbox-size');

const baseStyleControl = defineStyle(() => {
  const bg = 'blue.400';
  const bgChecked = 'yellow.100';
  const transition = getDefaultTransitionProps();

  return {
    h: '24px',
    w: '24px',
    bg,
    borderColor: 'gray.200',
    ...transition,

    _hover: {
      bg: 'gray.100',
      borderColor: 'link_hovered',
    },
    _checked: {
      bg: bgChecked,
      borderColor: bgChecked,
      color: 'black',
      _hover: {
        bg: bgChecked,
        borderColor: bgChecked,
      },
    },
    _indeterminate: {
      bg: bgChecked,
      borderColor: bgChecked,
    },
  };
});

const sizes = {
  sm: definePartsStyle({
    control: { [$size.variable]: 'sizes.3' },
    label: { fontSize: 'sm' },
    icon: { fontSize: '3xs' },
  }),
  md: definePartsStyle({
    control: { [$size.variable]: 'sizes.4' },
    label: { fontSize: 'md' },
    icon: { fontSize: '2xs' },
  }),
  lg: definePartsStyle({
    control: { [$size.variable]: 'sizes.5' },
    label: { fontSize: 'md' },
    icon: { fontSize: '2xs' },
  }),
};

const baseStyleLabel = defineStyle({
  ml: 5,
  _disabled: { opacity: 0.2 },
});

const baseStyle = definePartsStyle(() => ({
  label: baseStyleLabel,
  control: runIfFn(baseStyleControl),
}));

const Checkbox = defineMultiStyleConfig({
  baseStyle,
  sizes,
});

export default Checkbox;
