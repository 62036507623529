import { Box, Flex, Hide, IconButton, useColorModeValue } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

import config from 'configs/app';
import { useScrollDirection } from 'lib/contexts/scrollDirection';
import IconSvg from 'ui/shared/IconSvg';
import NetworkLogo from 'ui/snippets/networkMenu/NetworkLogo';
import ProfileMenuMobile from 'ui/snippets/profileMenu/ProfileMenuMobile';
import SearchBar from 'ui/snippets/searchBar/SearchBar';

import Burger from './Burger';

const LOGO_IMAGE_PROPS = {
  margin: '0 auto',
};

type Props = {
  hideSearchBar?: boolean;
  renderSearchBar?: () => React.ReactNode;
}

const HeaderMobile = ({ hideSearchBar, renderSearchBar }: Props) => {
  const router = useRouter();
  const bgColor = useColorModeValue('background', 'background');
  const scrollDirection = useScrollDirection();
  const { ref, inView } = useInView({ threshold: 1 });

  const searchBar = renderSearchBar ? renderSearchBar() : <SearchBar/>;
  const onSearch = useCallback(() => {
    router.push({ pathname: '/search-results', query: undefined }, undefined, { shallow: true });
  }, [ router ]);

  return (
    <>
      <Box
        ref={ ref }
        bgColor={ bgColor }
        display={{ base: 'block', lg: 'none' }}
        position="sticky"
        top="-1px"
        left={ 0 }
        zIndex="sticky"
        pt="1px"
      >
        <Flex
          as="header"
          paddingX={ 4 }
          paddingY={ 2 }
          bgColor={ bgColor }
          borderBottom="1px solid"
          borderColor="divider"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          transitionProperty="box-shadow"
          transitionDuration="slow"
          boxShadow={ !inView && scrollDirection === 'down' ? 'md' : 'none' }
        >
          <Burger/>
          <NetworkLogo imageProps={ LOGO_IMAGE_PROPS }/>
          <Flex columnGap={ 2 }>
            { config.features.account.isEnabled ? <ProfileMenuMobile/> : <Box boxSize={ 10 }/> }
            { /* { config.features.blockchainInteraction.isEnabled && <WalletMenuMobile/> } */ }
          </Flex>
          <IconButton
            onClick={ onSearch }
            icon={ <IconSvg name="search" boxSize={ 6 }/> }
            aria-label="search button icon"
            position="absolute"
            right={ 6 }
            w="24px"
            h="24px"
            backgroundColor="transparent"
          />
        </Flex>
      </Box>
      { !hideSearchBar && <Hide above="lg">{ searchBar }</Hide> }
    </>
  );
};

export default React.memo(HeaderMobile);
