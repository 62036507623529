import type { ChakraProps } from '@chakra-ui/react';
import { InputGroup, Input, InputLeftElement, chakra, useColorModeValue, forwardRef, InputRightElement } from '@chakra-ui/react';
import React from 'react';
import type { ChangeEvent, FormEvent, FocusEvent } from 'react';

import useIsMobile from 'lib/hooks/useIsMobile';
import ClearButton from 'ui/shared/ClearButton';
import IconSvg from 'ui/shared/IconSvg';

interface Props {
  onChange: (value: string) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onBlur?: (event: FocusEvent<HTMLFormElement>) => void;
  onFocus?: () => void;
  onHide?: () => void;
  onClear: () => void;
  isHomepage?: boolean;
  isSuggestOpen?: boolean;
  value: string;
  inputStyles?: ChakraProps;
  formStyles?: ChakraProps;
}

const SearchBarInput = (
  { onChange, onSubmit, isHomepage, isSuggestOpen, onFocus, onBlur, onClear, value, formStyles, inputStyles }: Props,
  ref: React.ForwardedRef<HTMLFormElement>,
) => {
  const innerRef = React.useRef<HTMLFormElement>(null);
  React.useImperativeHandle(ref, () => innerRef.current as HTMLFormElement, []);
  const isMobile = useIsMobile();

  const handleChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, [ onChange ]);

  const height = '50px';

  return (
    <chakra.form
      ref={ innerRef }
      noValidate
      onSubmit={ onSubmit }
      onBlur={ onBlur }
      onFocus={ onFocus }
      bg="transparent"
      maxW="407px"
      w="100%"
      h={ height }
      ml={ 10 }
      border="1px solid"
      borderColor="divider"
      borderRadius="sm"
      borderBottomRadius={ isSuggestOpen ? 'unset' : 'sm' }
      position="relative"
      left="0"
      zIndex="sticky"
      transitionProperty="transform,box-shadow,background-color,color,border-color"
      transitionDuration="normal"
      transitionTimingFunction="ease"
      { ...formStyles }
    >
      <InputGroup size={{ base: 'base' }}>
        <InputLeftElement w={{ base: 4 }} ml={{ base: 4 }} h="100%">
          <IconSvg name="search" boxSize={{ base: 4 }} color={ useColorModeValue('gray.100', 'gray.100') }/>
        </InputLeftElement>
        <Input
          h={ height }
          pl={ 10 }
          sx={{
            pr: isSuggestOpen ? 10 : 4,
            py: 4,
            fontSize: inputStyles?.fontSize ?? '10px',
          }}
          placeholder={ isMobile ? 'Search by address / ... ' : 'Search by address, token symbol, name, transaction hash, or block number' }
          onChange={ handleChange }
          borderRadius="sm"
          border={ isHomepage ? 'none' : '2px solid' }
          borderColor={ useColorModeValue('blackAlpha.100', 'whiteAlpha.200') }
          _focusWithin={{ _placeholder: { color: 'gray.300' } }}
          _focusVisible={{ borderColor: 'link_hovered' }}
          _placeholder="gray.100"
          color="white"
          value={ value }
          { ...inputStyles }
        />
        { value && (
          <InputRightElement insetY={ 0 } right={ 2 }>
            <ClearButton onClick={ onClear }/>
          </InputRightElement>
        ) }
      </InputGroup>
    </chakra.form>
  );
};

export default React.memo(forwardRef(SearchBarInput));
