const semanticTokens = {
  colors: {
    background: {
      'default': 'blue.900',
    },
    activeBackground: {
      'default': 'blue.600',
    },
    divider: {
      'default': 'blue.600',
    },
    text: {
      'default': 'white',
    },
    text_secondary: {
      'default': 'lightBlue.100',
    },
    link: {
      'default': 'white',
    },
    link_hovered: {
      'default': 'yellow.100',
    },
    error: {
      'default': 'red.400',
      _dark: 'red.300',
    },
  },
  shadows: {
    action_bar: '0 4px 4px -4px rgb(0 0 0 / 10%), 0 2px 4px -4px rgb(0 0 0 / 6%)',
  },
};

export default semanticTokens;
