import { mode } from '@chakra-ui/theme-tools';
import type { StyleFunctionProps } from '@chakra-ui/theme-tools';

const scrollbar = (props: StyleFunctionProps) => {
  const bgColorTrack = mode('background', 'background')(props);
  const bgColorThumb = mode('gray.400', 'gray.400')(props);
  const resizerUrl = mode('url(/static/resizer_dark.png)', 'url(/static/resizer_dark.png)')(props);

  return {
    'body *::-webkit-scrollbar': {
      width: '6px',
    },
    'body *::-webkit-scrollbar-track': {
      backgroundColor: bgColorTrack,
    },
    'body *::-webkit-scrollbar-thumb': {
      backgroundColor: bgColorThumb,
      borderRadius: '4px',
      minHeight: '32px',
    },
    'body *::-webkit-resizer': {
      backgroundImage: resizerUrl,
      backgroundSize: '16px',
    },
  };
};

export default scrollbar;
