import { selectAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

import getDefaultTransitionProps from 'theme/utils/getDefaultTransitionProps';

import Input from './Input';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const variantOutline = definePartsStyle((props) => {
  return {
    field: {
      ...Input.variants?.outline(props).field,
      borderColor: mode('gray.200', 'gray.600')(props),
      _hover: {
        borderColor: mode('gray.300', 'gray.500')(props),
      },
      _focusVisible: {
        borderColor: mode('gray.200', 'gray.600')(props),
        boxShadow: 'none',
      },
      cursor: 'pointer',
    },
  };
});

const variantPrimary = definePartsStyle((props) => {
  return {
    field: {
      w: '91px',
      h: '36px',
      bg: mode('activeBackground', 'activeBackground')(props),
      color: 'gray.100',
      borderRadius: 'sm',
      border: '1px solid',
      cursor: 'pointer',
      borderColor: mode('activeBackground', 'activeBackground')(props),
      _hover: {
        borderColor: mode('link_hovered', 'link_hovered')(props),
      },
      _active: {
        '& + div': {
          color: 'link_hovered',
          svg: {
            transform: 'rotate(180deg)',
          },
        },
      },
    },
    icon: {
      color: 'gray.100',
      svg: {
        ...getDefaultTransitionProps({ transitionProperty: 'transform' }),
      },
    },
  };
});

const iconSpacing = defineStyle({
  paddingInlineEnd: '8',
});

const sizes = {
  lg: {
    ...Input.sizes?.lg,
    field: {
      ...Input.sizes?.lg.field,
      ...iconSpacing,
    },
  },
  md: {
    ...Input.sizes?.md,
    field: {
      ...Input.sizes?.md.field,
      ...iconSpacing,
    },
  },
  sm: {
    ...Input.sizes?.sm,
    field: {
      ...Input.sizes?.sm.field,
      ...iconSpacing,
    },
  },
  xs: {
    ...Input.sizes?.xs,
    field: {
      ...Input.sizes?.xs.field,
      ...iconSpacing,
      fontSize: 'sm',
      lineHeight: '20px',
    },
  },
};

const Select = defineMultiStyleConfig({
  variants: {
    ...Input.variants,
    outline: variantOutline,
    primary: variantPrimary,
  },
  sizes,
  defaultProps: {
    size: 'xs',
  },
});

export default Select;
