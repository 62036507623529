import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

import colors from 'theme/foundations/colors';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStylePopper = defineStyle({
  zIndex: 'popover',
});

const baseStyleContent = defineStyle((props) => {
  const bg = mode('blue.650', 'blue.650')(props);

  return {
    bg,
    width: 'xs',
    border: 'none',
    borderColor: 'inherit',
    borderRadius: 'md',
    boxShadow: '2xl',
    zIndex: 'inherit',
    _focusVisible: {
      outline: 0,
      boxShadow: '2xl',
    },
  };
});

const baseStyleArrow = defineStyle({
  bgColor: `${ colors.blue[400] } !important`,
  boxShadow: `1px -1px 0px 0 ${ colors.blue[400] } !important`,
});

const baseStyleHeader = defineStyle({
  px: 3,
  py: 2,
  borderBottomWidth: '1px',
});

const baseStyleBody = defineStyle({
  px: 4,
  py: 4,
});

const baseStyleFooter = defineStyle({
  px: 3,
  py: 2,
  borderTopWidth: '1px',
  borderTopColor: 'divider',
});

const baseStyleCloseButton = defineStyle({
  position: 'absolute',
  borderRadius: 'md',
  top: 1,
  insetEnd: 2,
  padding: 2,
});

const baseStyle = definePartsStyle((props) => ({
  popper: baseStylePopper,
  content: baseStyleContent(props),
  header: baseStyleHeader,
  body: baseStyleBody,
  footer: baseStyleFooter,
  arrow: baseStyleArrow,
  closeButton: baseStyleCloseButton,
}));

const Popover = defineMultiStyleConfig({
  baseStyle,
});

export default Popover;
