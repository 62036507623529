/* eslint-disable max-len */
import { OrderedList, ListItem, chakra, Button } from '@chakra-ui/react';
import React from 'react';

import { route } from 'nextjs-routes';

import AppErrorTitle from '../AppErrorTitle';

const AppErrorTxNotFound = () => {
  return (
    <>
      <AppErrorTitle title="Sorry, we are unable to locate this transaction hash"/>
      <OrderedList mt={ 3 } spacing={ 3 }>
        <ListItem>
            If you have just submitted this transaction please wait for at least 30 seconds before refreshing this page.
        </ListItem>
        <ListItem>
            It could still be in the TX Pool of a different node, waiting to be broadcasted.
        </ListItem>
        <ListItem>
            During times when the network is busy (i.e during ICOs) it can take a while for your transaction to propagate through the network and for us to index it.
        </ListItem>
        <ListItem>
          <span>If it still does not show up after 1 hour, please check with your </span>
          <chakra.span fontWeight={ 600 }>sender/exchange/wallet/transaction provider</chakra.span>
          <span> for additional information.</span>
        </ListItem>
      </OrderedList>
      <Button
        mt={ 8 }
        size="lg"
        variant="outline"
        as="a"
        href={ route({ pathname: '/' }) }
      >
        Back to home
      </Button>
    </>
  );
};

export default AppErrorTxNotFound;
