import { chakra, Center, Link } from '@chakra-ui/react';
import React from 'react';

import type { IconName } from 'ui/shared/IconSvg';
import IconSvg from 'ui/shared/IconSvg';

type Props = {
  title?: string;
  icon?: IconName;
  url: string;
};

const FooterLinkItem = ({ title, icon, url }: Props) => {
  const { content, styles } = getContent(title, icon);
  return (
    <chakra.li listStyleType="none">
      <Link
        href={ url }
        variant="primary"
        target="_blank"
        { ...styles }
      >
        { content }
      </Link>
    </chakra.li>
  );
};

const getContent = (title?: string, icon?: IconName) => {
  if (title) {
    return {
      content: title,
      styles: {
        color: 'lightBlue.100',
      },
    };
  } else if (icon) {
    return {
      content: (
        <Center minW={ 6 }>
          <IconSvg w="30px" h="33px" name={ icon }/>
        </Center>
      ),
      styles: {
        h: '33px',
        display: 'flex',
        alignItems: 'center',
        color: 'yellow.100',
        _hover: {
          color: 'orange.100',
        },
      },
    };
  }
  return {
    content: null,
    styles: {},
  };
};

export default FooterLinkItem;
