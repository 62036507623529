import { chakra } from '@chakra-ui/react';
import React from 'react';

import getDefaultTransitionProps from 'theme/utils/getDefaultTransitionProps';

type Props = {
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  href?: string;
  target?: string;
  children: React.ReactNode;
}

const SearchBarSuggestItemLink = ({ onClick, href, target, children }: Props) => {
  return (
    <chakra.a
      py={ 3 }
      px={ 2 }
      display="flex"
      flexDir="column"
      rowGap={ 2 }
      borderRadius="base"
      border="1px solid transparent"
      _hover={{
        bgColor: 'blue.600',
        borderColor: 'link_hovered',
      }}
      fontSize="xs"
      onClick={ onClick }
      href={ href }
      target={ target }
      { ...getDefaultTransitionProps() }
    >
      { children }
    </chakra.a>
  );
};

export default SearchBarSuggestItemLink;
