import React, { createContext, useContext, useState } from 'react';

import { ViewModes } from 'types/views/viewModes';

type Props = {
  children: React.ReactNode;
}

type ViewModeContextType = {
  viewMode: ViewModes;
  setViewMode: (newViewMode: ViewModes) => void;
}

const ViewModeContext = createContext<ViewModeContextType>({
  viewMode: ViewModes.CARDS,
  setViewMode: () => {},
});

export function ViewModeProvider({ children }: Props) {
  const [ viewMode, setViewMode ] = useState<ViewModes>(ViewModes.CARDS);

  const updateViewMode = (newViewMode: ViewModes) => {
    setViewMode(newViewMode);
  };

  return (
    <ViewModeContext.Provider value={{
      viewMode,
      setViewMode: updateViewMode,
    }}>
      { children }
    </ViewModeContext.Provider>
  );
}

export function useViewModeContext() {
  return useContext(ViewModeContext);
}
