import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
} from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

import Button from './Button/Button';

const variantSoftRounded = definePartsStyle((props) => {
  return {
    tab: {
      borderRadius: '4px',
      fontWeight: '600',
      color: mode('gray.100', 'gray.100')(props),
      _selected: {
        color: mode('yellow.100', 'yellow.100')(props),
        bg: mode('activeBackground', 'activeBackground')(props),
        _hover: {
          color: mode('link_hovered', 'link_hovered')(props),
        },
      },
      _hover: {
        color: 'link_hovered',
      },
      _focusVisible: {
        boxShadow: { base: 'none', lg: 'outline' },
      },
    },
  };
});

const variantOutline = definePartsStyle(() => {
  return {
    tab: {
      padding: '12px 24px',
      borderRadius: '4px',
      fontWeight: '600',
      color: 'gray.100',
      borderColor: 'blue.600',
      borderWidth: 1,
      _selected: {
        color: 'white',
        bg: 'activeBackground',
      },
      _hover: {
        color: 'white',
      },
    },
  };
});

const variantFilled = definePartsStyle(() => {
  const selectedStyles = {
    color: 'white',
    bg: 'activeBackground',
    borderColor: 'activeBackground',
  };

  return {
    tab: {
      padding: 2,
      borderRadius: '2px',
      fontSize: 'xs',
      color: 'white',
      borderColor: 'blue.700',
      borderWidth: 1,
      bg: 'blue.700',
      _selected: {
        ...selectedStyles,
        _hover: selectedStyles,
      },
      _hover: {
        color: 'link_hovered',
        borderColor: 'link_hovered',
      },
    },
  };
});

const sizes = {
  sm: definePartsStyle({
    tab: Button.sizes?.sm,
  }),
  md: definePartsStyle({
    tab: Button.sizes?.md,
  }),
};

const variants = {
  'soft-rounded': variantSoftRounded,
  outline: variantOutline,
  filled: variantFilled,
};

const Tabs = defineMultiStyleConfig({
  sizes,
  variants,
});

export default Tabs;
