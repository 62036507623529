import getDefaultTransitionProps from 'theme/utils/getDefaultTransitionProps';

import useColors from './useColors';

type Props = {
  isHovered?: boolean;
  isExpanded?: boolean;
  isCollapsed?: boolean;
  isActive?: boolean;
  px?: string | number;
};

export default function useNavLinkProps({
  isHovered,
  isExpanded,
  isCollapsed,
  isActive,
}: Props) {
  const colors = useColors();
  const iconHover = {
    [`& .chakra-stack .chakra-skeleton`]: {
      transform: 'rotate(180deg)',
    },
  };

  return {
    itemProps: {
      py: 2.5,
      display: 'flex',
      color: isActive || isHovered ? colors.text.hover : colors.text.default,
      bgColor: isActive ? colors.bg.active : colors.bg.default,
      _hover: {
        color: 'link_hovered',
        ...iconHover,
      },
      borderRadius: 'base',
      ...getDefaultTransitionProps({
        transitionProperty: 'width, padding, opacity',
      }),
      px: isActive ? 2 : 0,
    },

    textProps: {
      color: 'inherit',
      fontSize: 'sm',
      fontWeight: 600,
      lineHeight: '18px',
      opacity: {
        base: '1',
        lg: isExpanded ? '1' : '0',
        xl: isCollapsed ? '0' : '1',
      },
      transitionProperty: 'opacity',
      transitionDuration: 'normal',
      transitionTimingFunction: 'ease',
    },
  };
}
