import { Flex, Box, VStack } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import { useScrollDirection } from 'lib/contexts/scrollDirection';
import useHasAccount from 'lib/hooks/useHasAccount';
import useNavItems, { isGroupItem } from 'lib/hooks/useNavItems';
import getDefaultTransitionProps from 'theme/utils/getDefaultTransitionProps';
import NetworkLogo from 'ui/snippets/networkMenu/NetworkLogo';
import NetworkMenu from 'ui/snippets/networkMenu/NetworkMenu';

import SearchBar from '../searchBar/SearchBar';
import NavLink from './NavLink';
import NavLinkGroupDesktop from './NavLinkGroupDesktop';

const NavigationDesktop = () => {

  const { mainNavItems, accountNavItems } = useNavItems();
  const scrollDirection = useScrollDirection();

  const hasAccount = useHasAccount();

  const isCollapsed = false;

  return (
    <Flex
      display={{ base: 'none', lg: 'flex' }}
      position={ scrollDirection === 'up' ? 'sticky' : 'relative' }
      top={ 0 }
      zIndex="sticky2"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="divider"
      bg="background"
      px={ 10 }
      py={ 4 }
      width="100%"
      height="82px"
      { ...getDefaultTransitionProps({ transitionProperty: 'width, padding' }) }
      sx={{
        '&:hover #expand-icon': {
          display: 'block',
        },
      }}
    >
      <Box
        as="header"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="row"
        w="100%"
        height="82px"
        transitionProperty="padding"
        transitionDuration="normal"
        transitionTimingFunction="ease"
      >
        <NetworkLogo isCollapsed={ isCollapsed }/>
        { Boolean(config.UI.sidebar.featuredNetworks) && <NetworkMenu isCollapsed={ isCollapsed }/> }
        <Box as="nav" flex={ 1 } maxWidth="371px" marginLeft="auto">
          <VStack as="ul" spacing="10" flexDirection="row" justifyContent="flex-end">
            { mainNavItems.map((item) => {
              if (isGroupItem(item)) {
                return <NavLinkGroupDesktop key={ item.text } item={ item } isCollapsed={ isCollapsed }/>;
              } else {
                return <NavLink key={ item.text } item={ item } isCollapsed={ isCollapsed }/>;
              }
            }) }
          </VStack>
        </Box>
        <SearchBar isHomepage/>
      </Box>
      { hasAccount && (
        <Box as="nav" borderTopWidth="1px" borderColor="divider" w="100%" mt={ 6 } pt={ 6 }>
          <VStack as="ul" spacing="1" alignItems="flex-start">
            { accountNavItems.map((item) => <NavLink key={ item.text } item={ item } isCollapsed={ isCollapsed }/>) }
          </VStack>
        </Box>
      ) }
    </Flex>
  );
};

export default NavigationDesktop;
