import { tableAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
} from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

import getDefaultTransitionProps from '../utils/getDefaultTransitionProps';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const borderStyle = '1px solid';
const borderColor = 'divider';
const borderRadius = 'lg';

const variantSimple = definePartsStyle((props) => {
  const transitionProps = getDefaultTransitionProps();
  const isNumericStyles = {
    '&[data-is-numeric=true]': {
      textAlign: 'left',
    },
  };
  const trLastStyles = {
    '& td': {
      borderBottom: borderStyle,
      borderBottomColor: borderColor,

      _first: {
        borderBottomLeftRadius: borderRadius,
      },
      _last: {
        borderBottomRightRadius: borderRadius,
      },
    },
  };

  return {
    th: {
      color: mode('gray.100', 'gray.100')(props),
      fontWeight: 400,
      borderTop: borderStyle,
      borderBottom: 0,
      borderTopColor: borderColor,

      _first: {
        borderLeft: borderStyle,
        borderLeftColor: borderColor,
        borderTopLeftRadius: borderRadius,
      },
      _last: {
        borderRight: borderStyle,
        borderRightColor: borderColor,
        borderTopRightRadius: borderRadius,
      },

      ...transitionProps,
      ...isNumericStyles,
    },
    thead: {
      bg: 'background !important',
      ...transitionProps,
    },
    table: {
      '&.lazy-rendered-list tr:nth-last-of-type(2)': {
        ...trLastStyles,
      },
      '& tr:last-child': {
        ...trLastStyles,
      },
    },
    td: {
      border: 0,

      _first: {
        borderLeft: borderStyle,
        borderColor: borderColor,
      },
      _last: {
        borderRight: borderStyle,
        borderColor: borderColor,
      },
      ...transitionProps,
      ...isNumericStyles,
    },
    tbody: {
      tr: {
        position: 'relative',
        zIndex: 1,
        _hover: {
          _after: {
            content: '""',
            position: 'absolute',
            w: 'calc(100% - 16px)',
            h: 'calc(100% - 8px)',
            bg: 'blue.700',
            inset: 0,
            margin: 'auto',
            zIndex: 'hide',
            borderRadius: 'sm',
          },
        },
      },
    },
  };
});

const notFirstLastChildStyles = {
  '&:not(:first-of-type):not(:last-of-type)': {
    pr: 4,
  },
};

const sizes = {
  md: definePartsStyle({
    th: {
      px: 0,
      py: 4,
      fontSize: 'md',
      ...notFirstLastChildStyles,
    },
    td: {
      px: 0,
      py: '21px',
      fontSize: 'sm',
      fontWeight: 600,
      ...notFirstLastChildStyles,
    },
  }),
  sm: definePartsStyle({
    th: {
      p: 4,
      fontSize: '16px',
    },
    td: {
      p: 4,
      fontSize: '14px',
      fontWeight: 600,
    },
  }),
  xs: definePartsStyle({
    th: {
      px: '6px',
      py: '10px',
      fontSize: 'sm',
    },
    td: {
      px: '6px',
      py: 4,
      fontSize: 'sm',
      fontWeight: 500,
    },
  }),
};

const variants = {
  simple: variantSimple,
};

const baseStyle = definePartsStyle({
  th: {
    textTransform: 'none',
    fontFamily: 'body',
    fontWeight: '500',
    overflow: 'hidden',
    color: 'gray.500',
    letterSpacing: 'none',
  },
  td: {
    fontSize: 'md',
    verticalAlign: 'middle',
  },
  table: {
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    borderSpacing: 0,
    borderRadius: borderRadius,
    bg: 'blue.850',
    overflow: 'unset',
    fontVariant: 'normal',
    fontVariantLigatures: 'no-contextual',
  },
});

const Table = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
});

export default Table;
